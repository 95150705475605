import axios from "axios";

export default class formulas {
  //static baseUrl = "https://sportchallenges.fun:5003/api";
  //static baseUrl = "http://127.0.0.1:5000/api";
  //static baseUrl = "http://192.168.1.123:5000/api";

  getRequest() {
    return "";
  }

  static paceFromKmh(speedKilometersPerHour) {
    if (speedKilometersPerHour == 0) return "00:00";
    let paceInMinutes = 60 / parseFloat(speedKilometersPerHour);
    let minutes = Math.floor(paceInMinutes);
    let seconds = Math.floor((paceInMinutes - minutes) * 60);
    let minutesZero = minutes < 10 ? "0" : "";
    let secondsZero = seconds < 10 ? "0" : "";
    //let hours = Math.floor(time / 3600);
    //time = time - hours * 3600;
    return `${minutesZero}${minutes.toFixed(0)}:${secondsZero}${seconds.toFixed(
      0
    )}`;
  }

  static getHeartRateZone(heartRatePercentage) {
    if (!heartRatePercentage) return null;
    let hrPercentage = parseFloat(heartRatePercentage);
    if (hrPercentage < 50) return 0;
    else if (hrPercentage >= 50 && hrPercentage < 60) return 1;
    else if (hrPercentage >= 60 && hrPercentage < 70) return 2;
    else if (hrPercentage >= 70 && hrPercentage < 80) return 3;
    else if (hrPercentage >= 80 && hrPercentage < 90) return 4;
    else if (hrPercentage >= 90 && hrPercentage < 100) return 5;
    else if (hrPercentage >= 100 && hrPercentage < 110) return 6;
    else if (hrPercentage >= 110 && hrPercentage < 120) return 7;
    else if (hrPercentage >= 120 && hrPercentage < 130) return 8;
    else {
      console.log(`Unknown heart rate zone because of percentage!`);
      return null;
    }
  }

  static getHeartRateZonePercentage(heartRatePercentage) {
    if (!heartRatePercentage) return null;
    let zonePercentage = ((parseFloat(heartRatePercentage) - 50) / 50) * 100;
    return zonePercentage.toFixed(2);
  }
}
