import axios from "axios";

export default class proxy {
  static baseUrl = "https://sportchallenges.fun:5001/api";
  //static baseUrl = "http://127.0.0.1:5000/api";
  //static baseUrl = "http://192.168.1.123:5000/api";

  getRequest() {
    return "";
  }

  static getToken() {
    return "Token-ABCDEFGH";
  }

  setData(data) {
    localStorage.setItem(
      "SecretKey",
      "Are you looking for something down here?"
    );
    localStorage.getItem("SecretKey");
  }
}
