<template>
  <v-container>
    <v-list-item
      :key="group.name"
      link
      @click="$router.push({ path: `/group/${group.groupId}` })"
    >
      <v-list-item-avatar>
        <!-- <img :src="`data:image/png;base64,${group.image}`" /> -->
        <img :src="group.image" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ group.name }}</v-list-item-title>

        <v-list-item-subtitle>{{ group.description }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-container>
</template>

<script>
import groupModel from "../models/groupModel.js";

export default {
  name: "ThumbnailGroupComponent",
  props: {
    group: Object,
  },
  data: () => ({}),
};
</script>
