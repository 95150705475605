<template>
  <v-container v-if="challenge">
    <v-card class="mx-auto my-1" max-width="1000">
      <v-container class="lighten-12">
        <v-row no-gutters>
          <v-col no-gutters class="pa-5" cols="1">
            <v-avatar tile size="60">
              <img :src="challenge.image" />
            </v-avatar>
          </v-col>
          <v-col
            left
            no-gutters
            class="pa-5"
            cols="10"
            style="
              padding-bottom: 0px !important;
              margin-bottom: 0px !important;
            "
          >
            <p
              style="
                padding-left: 25px;
                flex-wrap: wrap;
                font-size: 1.25rem;
                font-weight: 500;
                letter-spacing: 0.0125em;
                line-height: 2rem;
                word-break: break-all;
                margin-bottom: 0px;
              "
            >
              {{ challenge.name }}
            </p>
            <p
              style="
                padding-left: 25px;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.375rem;
                letter-spacing: 0.0071428571em;
              "
            >
              {{ challenge.description }}
            </p>
            <p
              style="
                padding-bottom: 0px !important;
                margin-bottom: 0px !important;
                padding-left: 25px;
                padding-bottom: 0px;
                font-size: 0.72rem;
                font-weight: 400;
                line-height: 1.375rem;
                letter-spacing: 0.0071428571em;
              "
            >
              {{ challenge.startDate.replace("T", " ") }} -
              {{ challenge.finishDate.replace("T", " ") }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue lighten-2" text>Rules</v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text>
            <v-row dense class="mb-1">
              <h3>Rules:</h3>
            </v-row>
            <div v-for="item in challenge.rules" :key="item.ruleId">
              <v-row dense class="mb-1">
                &nbsp;
                <h5>{{ item.challengeRule.challengeRuleId }}&nbsp;</h5>
                <p
                  v-if="item.challengeRule.challengeRuleId == 'MinimumDuration'"
                >
                  {{ (item.seconds / 60).toFixed(0) }}mins
                </p>
                <p
                  v-if="item.challengeRule.challengeRuleId == 'MinimumDistance'"
                >
                  {{ item.meters / 1000 }}km
                </p>
                <p
                  v-if="
                    item.challengeRule.challengeRuleId == 'AllowedWorkoutType'
                  "
                >
                  {{ item.workoutType.workoutTypeId }}
                </p>
                <p
                  v-if="
                    item.challengeRule.challengeRuleId == 'DeniedWorkoutType'
                  "
                >
                  {{ item.workoutType.workoutTypeId }}
                </p>
              </v-row>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>

    <v-tabs class="my-5" color="deep-purple accent-4" centered>
      <v-tab
        href="#challenge-tabs-awards"
        class="primary--text"
        @click="tabClicked('challenge-tabs-awards')"
        ><v-icon>mdi-trophy-broken</v-icon></v-tab
      >
      <v-tab
        href="#challenge-tabs-stats"
        class="primary--text"
        @click="tabClicked('challenge-tabs-stats')"
        ><v-icon>mdi-chart-bar-stacked</v-icon></v-tab
      >
      <v-tab
        href="#challenge-tabs-workouts"
        class="primary--text"
        @click="tabClicked('challenge-tabs-workouts')"
        ><v-icon>mdi-run-fast</v-icon></v-tab
      >
      <v-tab-item value="challenge-tabs-awards">
        <v-card class="mx-auto my-8" max-width="1000">
          <challenge-participant-position
            v-for="item in data"
            :key="item.participant"
            :participant="item"
            :challenge-type="challenge.challengeType"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item value="challenge-tabs-stats">
        <line-chart
          v-if="challenge.challengeType == 'TotalEffort'"
          :series="primaryDataChartValues"
          yaxisTitle=""
          title="Total"
        />
        <line-chart
          v-if="challenge.challengeType == 'TotalDistance'"
          :series="primaryDataChartValues"
          yaxisTitle=""
          title="Distance (km)"
        />
        <bar-chart
          v-if="challenge.challengeType == 'TotalDistance'"
          :challengeId="challengeId"
          dataType="Distance"
          yaxisTitle="kilometers"
          title="Distance by months"
          :splitByMonth="true"
        />
        <bar-chart
          v-if="challenge.challengeType == 'TotalEffort'"
          :challengeId="challengeId"
          dataType="Effort"
          yaxisTitle="points"
          title="Effort score by months"
          :splitByMonth="true"
        />
        <bar-chart
          :challengeId="challengeId"
          dataType="ActiveTimeTotal"
          yaxisTitle="minutes"
          title="Active time by months"
          :splitByMonth="true"
        />
        <bar-chart
          v-if="challenge.challengeType == 'TotalDistance'"
          :challengeId="challengeId"
          dataType="Distance"
          yaxisTitle="kilometers"
          title="Distance by week days"
          :splitByDay="true"
        />
        <bar-chart
          v-if="challenge.challengeType == 'TotalEffort'"
          :challengeId="challengeId"
          dataType="Effort"
          yaxisTitle="points"
          title="Effort score by week days"
          :splitByDay="true"
        />
        <bar-chart
          :challengeId="challengeId"
          dataType="ActiveTimeTotal"
          yaxisTitle="minutes"
          title="Active time by week days"
          :splitByDay="true"
        />
      </v-tab-item>
      <v-tab-item value="challenge-tabs-workouts">
        <v-card class="mx-auto my-8" max-width="1000" flat v-if="getWorkouts">
          <v-list two-line subheader>
            <thumbnail-workout-component
              v-for="item in getWorkouts"
              :key="item.workoutId"
              :workout="item"
            />
          </v-list>
          <v-pagination
            v-model="page"
            :length="paginationLength"
            @input="onPageChange"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import ApexCharts from "apexcharts";
import { mapState, mapGetters, mapActions } from "vuex";
import ChallengeParticipantPosition from "../components/ChallengeParticipantPosition";
import ThumbnailWorkoutComponent from "../components/ThumbnailWorkoutComponent";
import LineChart from "../components/charts/LineChart.vue";
import BarChart from "../components/charts/BarChart.vue";

export default {
  name: "ChallengeDetailView",
  props: {
    challengeId: String,
  },
  components: {
    ChallengeParticipantPosition,
    ThumbnailWorkoutComponent,
    LineChart,
    BarChart,
  },
  data() {
    return {
      page: 1,
      tab: null,
      show: false,
    };
  },
  mounted() {
    this.getChallenge(this.challengeId);
    this.getData(this.challengeId);
    this.getCountByChallenge(this.challengeId);
  },
  computed: {
    ...mapState("challenges", {
      challenge: (state) => state.challenge,
      data: (state) => state.data,
    }),
    ...mapState("workouts", {
      challengeWorkoutsCount: (state) => state.challengeWorkoutsCount,
    }),
    ...mapState("charts", {
      primaryDataChartValues: (state) => state.primaryDataChartValues,
    }),
    ...mapGetters("workouts", ["getWorkouts"]),
    paginationLength: function () {
      return parseInt(this.challengeWorkoutsCount / 50 + 1);
    },
  },
  methods: {
    ...mapActions("challenges", ["getChallenge", "getData"]),
    ...mapActions("workouts", ["getByChallenge", "getCountByChallenge"]),
    ...mapActions("charts", ["getPrimaryDataChartValues"]),
    tabClicked(tabName) {
      if (tabName == "challenge-tabs-awards") {
        this.getChallenge(this.challengeId);
        this.getData(this.challengeId);
      } else if (tabName == "challenge-tabs-stats") {
        this.getPrimaryDataChartValues({
          challengeId: this.challengeId,
          start: null,
          finish: null,
        });
      } else if (tabName == "challenge-tabs-workouts") {
        this.getByChallenge({ primaryKey: this.challengeId, skip: 0 });
      } else {
        console.log(
          `Failed to load tab data as tab (${tabName}) is not known!`
        );
      }
    },
    onPageChange(page) {
      this.getByChallenge({
        primaryKey: this.challengeId,
        skip: page * 50 - 50,
      });
      scroll(0, 0);
    },
  },
};
</script>
