import Vue from "vue";
import Vuex from "vuex";
import charts from "./modules/charts";
import groups from "./modules/groups";
import workouts from "./modules/workouts";
import challenges from "./modules/challenges";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    charts,
    groups,
    workouts,
    challenges,
  },
});
