export default class baseDataModel {
  constructor(name) {
    //console.log("Class name: " + name);
  }

  deserialize(jsonObject) {
    for (var prop in jsonObject) {
      this[prop] = jsonObject[prop];
    }
  }
}
