<template>
  <v-container>
    <ul v-for="group in groups" :key="group.GroupId">
      <v-list two-line subheader>
        <thumbnail-group-component :group="group" />
      </v-list>
    </ul>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ThumbnailGroupComponent from "../components/ThumbnailGroupComponent";

export default {
  name: "GroupsView",
  components: {
    ThumbnailGroupComponent,
  },
  mounted() {
    this.getGroups();
  },
  computed: {
    ...mapState("groups", {
      groups: (state) => state.groups,
    }),
    //...mapGetters("groups", ["groups"]),
    // groups() {
    //   return this.$store.groups.groups;
    // },
  },
  methods: {
    ...mapActions("groups", ["getGroups"]), // -> this.foo()
  },
};
</script>
