import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    defaultTheme: "myCustomTheme",
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: "#007BFF",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        background: "#00BCD4",
        surface: "#009688",
        primary: "#795548",
        "primary-darken-1": "#795548",
        secondary: "#4CAF50",
        "secondary-darken-1": "#4CAF50",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
});
