<template>
  <v-list-item
    v-if="challenge"
    :key="challenge.challengeId"
    link
    @click="$router.push({ path: `/challenge/${challenge.challengeId}` })"
  >
    <v-list-item-avatar>
      <img :src="challenge.image" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ challenge.name }}</v-list-item-title>
      <v-list-item-subtitle>
        {{ challenge.description }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ challenge.startDate.replace("T", " ") }} -
        {{ challenge.finishDate.replace("T", " ") }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "ThumbnailChallengeComponent",
  props: {
    challenge: Object,
  },
  data: () => ({}),
  computed: {
    startDate: function () {
      if (!this.challenge) return null;
      else {
        let today = new Date(this.challenge.startDate);
        let date = `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`;
        return date;
      }
    },
    startTime: function () {
      if (!this.challenge) return null;
      else {
        let today = new Date(this.challenge.startDate);
        let time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
        return time;
      }
    },
    finishTime: function () {
      if (!this.challenge) return null;
      else {
        let today = new Date(this.challenge.finishDate);
        let time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
        return time;
      }
    },
  },
};
</script>
