<template>
  <v-container v-if="series">
    <v-card class="mx-auto my-12" max-width="1000">
      <apexchart type="line" :options="options" :series="series"></apexchart>
    </v-card>
  </v-container>
</template>

<script>
import ApexCharts from "apexcharts";
import { mapState, mapActions } from "vuex";

export default {
  name: "LineChart",
  props: {
    series: Array,
    title: String,
    yaxisTitle: String,
  },
  components: {},
  data() {
    return {
      page: 1,
      tab: null,
      show: false,
      options: {
        colors: [
          "#4f8fff",
          "#1ac458",
          "#ff7c00",
          "#FC3A7C",
          "#c8bb7e",
          "#a35adb",
          "#32a894",
          "#0f4763",
          "#310f63",
          "#b7cf08",
          "#ffd65c",
          "#9e9090",
          "#86c2e3",
          "#630f2d",
        ],
        chart: {
          type: "line",
        },
        title: {
          text: this.title,
          align: "middle",
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          title: {
            text: this.yaxisTitle,
          },
        },
      },
    };
  },
  mounted() {
    // this.getPrimaryDataChartValues({
    //   challengeId: this.challengeId,
    //   start: null,
    //   finish: null,
    // });
  },
  computed: {
    ...mapState("charts", {
      primaryDataChartValues: (state) => state.primaryDataChartValues,
    }),
  },
  methods: {
    ...mapActions("charts", ["getPrimaryDataChartValues"]),
  },
};
</script>
