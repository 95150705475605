import axios from "axios";
import proxy from "../../commons/proxy";
import challengeModel from "../../models/challengeModel.js";

export default {
  namespaced: true,

  // Initial state
  state: {
    data: [],
    challenges: [],
    challenge: null,
  },

  // Getters
  getters: {
    getChallenges: (state, getters, rootState) => {
      return state.challenges;
    },

    getChallenge: (state, getters, rootState) => {
      return state.challenge;
    },
  },

  // Mutations
  mutations: {
    SET_Challenge(state, item) {
      if (item) {
        let dataObject = new challengeModel(item);
        state.challenge = dataObject;
      }
    },
    SET_Data(state, items) {
      if (items) {
        let objectsCollection = [];
        for (let index = 0; index < items.length; ++index) {
          let jsonObject = items[index];
          let dataObject = new challengeModel(jsonObject);
          objectsCollection.push(dataObject);
        }
        state.data = objectsCollection;
      } else {
        console.log("Failed to deserialize challenge data for collection!");
      }
    },
    SET_Challenges(state, items) {
      if (items) {
        let objectsCollection = [];
        for (let index = 0; index < items.length; ++index) {
          let jsonObject = items[index];
          let dataObject = new challengeModel(jsonObject);
          objectsCollection.push(dataObject);
        }
        state.challenge = objectsCollection;
      } else {
        console.log("Failed to deserialize challenge for collection!");
      }
    },
  },

  // Action
  actions: {
    getChallenge({ commit, state }, primaryKey) {
      commit("SET_Challenge", null);
      axios
        .get(`${proxy.baseUrl}/challenge/get/${primaryKey}`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Challenge", response.data);
        });
    },
    getData({ commit, state }, primaryKey) {
      commit("SET_Data", []);
      axios
        .get(`${proxy.baseUrl}/challenge/getData?challengeId=${primaryKey}`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Data", response.data);
        });
    },
    getChallenges({ commit, state }, data) {
      commit("SET_Challenges", []);
      axios
        .get(`${proxy.baseUrl}/challenge/get`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Challenges", response.data);
        });
    },
  },
};
