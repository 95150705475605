import baseDataModel from "./baseDataModel";

// [{"groupId":1,"name":"Rubedos","icon":null,"description":"Rubedso company sport challenges group","isPrivate":false,"users":null,"challenges":null}]
export default class groupModel extends baseDataModel {
  groupId = null;
  name = null;
  icon = null;
  description = null;
  isPrivate = null;
  users = null;
  challenges = null;

  constructor(jsonObject) {
    super("group"); // call the super class constructor and pass in the name parameter
    this.deserialize(jsonObject);
  }
}
