import baseDataModel from "./baseDataModel";

// groupUserAssignId	1
// user	: { userId: 1, firstName: "Mantas", lastName: "Bistrubis", … }
// userId	1
// firstName	"Mantas"
// lastName	"Bistrubis"
// abbreviation	"MBI"
// password	"NotEncryptedYet"
// email	"bis.mantas@gmail.com"
// providers	null
// weights	null
// heights	null
// group	null
// isOwner	false
// isAdmin	true
// isModerator	false
// joinRequestDatetime	"2022-01-13T22:13:14.3152042"
// joinApproveDateTime	"2022-01-13T23:13:14.3152318"
export default class groupUserAssignId extends baseDataModel {
  constructor(jsonObject) {
    super("groupUserAssign"); // call the super class constructor and pass in the name parameter
    this.deserialize(jsonObject);
  }
}
