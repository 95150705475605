import baseDataModel from "./baseDataModel";

// {
//   "workoutId": 50,
//   "user": {
//     "userId": 1,
//     "firstName": "Mantas",
//     "lastName": "Bistrubis",
//     "abbreviation": "DKA",
//     "password": "NotEncryptedYet",
//     "email": "mantas.bistrubis@rubedos.com",
//     "providers": null,
//     "weights": null,
//     "heights": null
//   },
//   "name": "25.60km Road Cycling",
//   "notes": "",
//   "privacy": 3,
//   "mapPrivacy": 3,
//   "workoutType": 36,
//   "isVerified": false,
//   "hasTimeSeries": true,
//   "hasGpsData": true,
//   "hasHeartRateData": false,
//   "startDateTime": "2021-04-11T13:46:59",
//   "activeTimeTotal": "01:09:20",
//   "finishTime": "2021-04-11T15:00:11",
//   "totals": [
//     {
//       "workoutDataId": 482,
//       "workout": null,
//       "workoutDataType": 1,
//       "value": "25604,180236800003",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 483,
//       "workout": null,
//       "workoutDataType": 2,
//       "value": "4160",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 484,
//       "workout": null,
//       "workoutDataType": 3,
//       "value": "4392",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 485,
//       "workout": null,
//       "workoutDataType": 4,
//       "value": "32,584119744",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 486,
//       "workout": null,
//       "workoutDataType": 5,
//       "value": "0,09855667359999999",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 487,
//       "workout": null,
//       "workoutDataType": 6,
//       "value": "6,155606688000001",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 488,
//       "workout": null,
//       "workoutDataType": 16,
//       "value": "3581504",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 489,
//       "workout": null,
//       "workoutDataType": 17,
//       "value": "161",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 490,
//       "workout": null,
//       "workoutDataType": 18,
//       "value": "92",
//       "description": null,
//       "order": null
//     },
//     {
//       "workoutDataId": 491,
//       "workout": null,
//       "workoutDataType": 19,
//       "value": "147",
//       "description": null,
//       "order": null
//     }
//   ]
// },
export default class workoutModel extends baseDataModel {
  constructor(jsonObject) {
    super("activity"); // call the super class constructor and pass in the name parameter
    this.deserialize(jsonObject);
  }
}
