<template>
  <v-list-item
    v-if="user"
    :key="user.groupUserAssignId"
    link
    @click="openMapMyFitnessUser"
  >
    <v-list-item-avatar>
      <v-icon large color="blue darken-2">mdi-account-circle</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        >{{ user.firstName }} {{ user.lastName }}.</v-list-item-title
      >
      <v-list-item-subtitle>
        {{
          user.joinApproveDateTime.replace("T", " ").replaceAll(/\.\d+/gi, "")
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "ThumbnailUserComponent",
  props: {
    user: Object,
  },
  mounted() {},
  data: () => ({}),
  methods: {
    openMapMyFitnessUser: function (event) {
      // @click="$router.push({ path: `/user/${user.userId}` })"
      window.open(this.user.url, "_blank");
    },
  },
};
</script>
