<template>
  <v-container v-if="workout">
    <v-divider inset></v-divider>
    <v-card>
      <h2>Under construction! {{ workout.name }}</h2>
      <br />
      <br />
      <br />
      <v-btn v-on:click="openMapMyFitnessWorkout" color="blue">
        Open in MapMyFitness
      </v-btn>
      <br />
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "WorkoutDetailView",
  props: {
    workoutId: String,
  },
  data() {
    return {
      tab: null,
    };
  },
  mounted() {
    this.getWorkout(this.workoutId);
  },
  computed: {
    ...mapState("workouts", {
      workout: (state) => state.workout,
    }),
  },
  methods: {
    ...mapActions("workouts", ["getWorkout"]),
    openMapMyFitnessWorkout: function (event) {
      window.open(this.workout.url, "_blank");
    },
  },
  components: {},
};
</script>
