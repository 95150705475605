import axios from "axios";
import proxy from "../../commons/proxy";
import workoutModel from "../../models/workoutModel.js";

export default {
  namespaced: true,

  // Initial state
  state: {
    workouts: null,
    workout: null,
    groupWorkoutsCount: 0,
    challengeWorkoutsCount: 0,
  },

  // Getters
  getters: {
    getWorkouts: (state, getters, rootState) => {
      return state.workouts;
    },

    getWorkout: (state, getters, rootState) => {
      return state.workout;
    },
  },

  // Mutations
  mutations: {
    SET_Workout(state, item) {
      if (item) {
        let dataObject = new workoutModel(item);
        state.workout = dataObject;
      } else {
        console.log("Failed to deserialize workout!");
      }
    },
    SET_Workouts(state, items) {
      if (items) {
        let deserializedCollection = [];
        for (let index = 0; index < items.length; ++index) {
          let groupJsonObject = items[index];
          let dataObject = new workoutModel(groupJsonObject);
          deserializedCollection.push(dataObject);
        }
        state.workouts = deserializedCollection;
      } else {
        console.log("Failed to deserialize group activities!");
      }
    },
    SET_GROUP_WORKOUTS_COUNT(state, item) {
      if (item) {
        state.groupWorkoutsCount = item;
      } else {
        console.log("Failed to set group workouts count!");
      }
    },
    SET_CHALLENGE_WORKOUTS_COUNT(state, item) {
      if (item) {
        state.challengeWorkoutsCount = item;
      } else {
        console.log("Failed to set challenge workouts count!");
      }
    },
    RESET(state) {
      state.workout = null;
      state.workouts = null;
    },
  },

  // Action
  actions: {
    getWorkout({ commit, state }, primaryKey) {
      axios
        .get(`${proxy.baseUrl}/workout/get/${primaryKey}`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Workout", response.data);
        });
    },
    getWorkouts({ commit, state }, data) {
      axios
        .get(`${proxy.baseUrl}/workout/get`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Workouts", response.data);
        });
    },
    getByGroup({ commit, state }, { primaryKey, skip }) {
      axios
        .get(
          `${proxy.baseUrl}/workout/getByGroup?groupId=${primaryKey}&skip=${skip}&take=50`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_Workouts", response.data);
        });
    },
    getByChallenge({ commit, state }, { primaryKey, skip }) {
      axios
        .get(
          `${proxy.baseUrl}/workout/getByChallenge?challengeId=${primaryKey}&skip=${skip}&take=50`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_Workouts", response.data);
        });
    },
    getCountByGroup({ commit, state }, groupId) {
      axios
        .get(`${proxy.baseUrl}/workout/getCountByGroup?groupId=${groupId}`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_GROUP_WORKOUTS_COUNT", response.data);
        });
    },
    getCountByChallenge({ commit, state }, challengeId) {
      axios
        .get(
          `${proxy.baseUrl}/workout/getCountByChallenge?challengeId=${challengeId}`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_CHALLENGE_WORKOUTS_COUNT", response.data);
        });
    },
  },
};
