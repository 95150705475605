import baseDataModel from "./baseDataModel";

// challengeId	1
// challengeType	: { challengeTypeId: 1, name: "TotalDistance", description: "Most distance collected", … }
// owner : { userId: 1, firstName: null, lastName: null, … }
// name	"Rubedos cycling most distance"
// description	"Collect most kilometers with bicycle and win prize!"
// icon	null
// password	null
// isApprovalRequired	true
// startDate	"2021-01-01T00:00:00"
// finishDate	"2021-12-31T00:00:00"
// participants	[]
// rules	[]
// workouts	[]
export default class challengeModel extends baseDataModel {
  constructor(jsonObject) {
    super("challenge"); // call the super class constructor and pass in the name parameter
    this.deserialize(jsonObject);
  }
}
