<template>
  <v-container v-if="workout">
    <v-divider inset></v-divider>
    <v-card>
      <h2>User id: {{ userId }}</h2>
      <h2>User name {{ userName }}</h2>
      <br />
      <br />
      <br />
      <v-btn v-on:click="openMapMyFitnessWorkout" color="blue">
        Open in MapMyFitness
      </v-btn>
      <br />
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ProviderAuthDetailView",
  props: {
    userId: String,
    userName: String,
  },
  data() {
    return {
      tab: null,
    };
  },
  mounted() {},
  computed: {
    ...mapState("workouts", {
      workout: (state) => state.workout,
    }),
  },
  methods: {
    ...mapActions("workouts", ["getWorkout"]),
    openMapMyFitnessWorkout: function (event) {
      window.open(this.workout.url, "_blank");
    },
  },
  components: {},
};
</script>
