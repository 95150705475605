<template>
  <v-container v-if="chartSeries">
    <v-card class="mx-auto my-12" max-width="1000">
      <apexchart
        type="bar"
        height="400"
        :options="options"
        :series="chartSeries"
      ></apexchart>
    </v-card>
  </v-container>
</template>

<script>
import ApexCharts from "apexcharts";
import { mapState, mapActions } from "vuex";

export default {
  name: "BarChart",
  props: {
    challengeId: String,
    dataType: String,
    title: String,
    yaxisTitle: String,
    splitByDay: Boolean,
    splitByMonth: Boolean,
  },
  components: {},
  data() {
    return {
      page: 1,
      tab: null,
      show: false,
      options: {
        colors: [
          "#4f8fff",
          "#1ac458",
          "#ff7c00",
          "#FC3A7C",
          "#c8bb7e",
          "#a35adb",
          "#32a894",
          "#0f4763",
          "#310f63",
          "#b7cf08",
          "#ffd65c",
          "#9e9090",
          "#86c2e3",
          "#630f2d",
        ],
        chart: {
          type: "bar",
          height: 400,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: this.title,
          align: "middle",
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        // xaxis: {
        //   categories: [
        //     "Monday",
        //     "Tuesday",
        //     "Wednesday",
        //     "Thursday",
        //     "Friday",
        //     "Saturday",
        //     "Sunday",
        //   ],
        // },
        yaxis: {
          title: {
            text: this.yaxisTitle,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          //   console.log(series);
          //   console.log(seriesIndex);
          //   console.log(dataPointIndex);
          //   console.log(w);
          //   let data =
          //     w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          //   let yaxisTitle = w.config.yaxis[0].title.text;

          //   if (yaxisTitle == "minutes")
          //     return `${data.y} ${yaxisTitle} (${(data.y / 60).toFixed(2)}h)`;
          //   else
          //     return (
          //       '<div class="arrow_box">' +
          //       "<span>" +
          //       `${data.y} ${yaxisTitle}` +
          //       "</span>" +
          //       "</div>"
          //     );
          // },
          y: {
            formatter: function (
              val,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              let data =
                w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              let yaxisTitle = w.config.yaxis[0].title.text;
              if (yaxisTitle == "minutes")
                return `${val} ${yaxisTitle} (${(val / 60).toFixed(2)}h)`;
              else return `${val} ${yaxisTitle}`;
            },
          },
          // y: {
          //   formatter: function (val) {
          //     if (this.yaxisTitle == "minutes")
          //       return `${val} ${this.yaxisTitle} (${(val / 60).toFixed(2)}h)`;
          //     else return `${val} ${this.yaxisTitle}`;
          //   },
          // },
        },
      },
    };
  },
  mounted() {
    console.log("zzzz");
    if (this.dataType == "ActiveTimeTotal") {
      if (this.splitByDay)
        this.getChallengeDurationByWeekDays({
          challengeId: this.challengeId,
          start: null,
          finish: null,
        });
      if (this.splitByMonth)
        this.getChallengeDurationByMonths({
          challengeId: this.challengeId,
          start: null,
          finish: null,
        });
    } else if (this.dataType == "Effort") {
      if (this.splitByDay)
        this.getChallengeEffortByWeekDays({
          challengeId: this.challengeId,
          start: null,
          finish: null,
        });
      else if (this.splitByMonth)
        this.getChallengeEffortByMonths({
          challengeId: this.challengeId,
          start: null,
          finish: null,
        });
    } else if (this.dataType == "Distance") {
      if (this.splitByDay)
        this.getChallengeDistanceByWeekDays({
          challengeId: this.challengeId,
          start: null,
          finish: null,
        });
      else if (this.splitByMonth)
        this.getChallengeDistanceByMonths({
          challengeId: this.challengeId,
          start: null,
          finish: null,
        });
    }
  },
  computed: {
    ...mapState("charts", {
      primaryDataChartValues: (state) => state.primaryDataChartValues,
      challengeDurationByWeekDaysValues: (state) =>
        state.challengeDurationByWeekDaysValues,
      challengeDurationByMonthsValues: (state) =>
        state.challengeDurationByMonthsValues,
      challengeEffortByWeekDaysValues: (state) =>
        state.challengeEffortByWeekDaysValues,
      challengeEffortByMonthsValues: (state) =>
        state.challengeEffortByMonthsValues,
      challengeDistanceByWeekDaysValues: (state) =>
        state.challengeDistanceByWeekDaysValues,
      challengeDistanceByMonthsValues: (state) =>
        state.challengeDistanceByMonthsValues,
    }),
    chartSeries: function () {
      if (this.dataType == "ActiveTimeTotal") {
        if (this.splitByMonth && this.challengeDurationByMonthsValues != null)
          return this.challengeDurationByMonthsValues.series;
        else if (
          this.splitByDay &&
          this.challengeDurationByWeekDaysValues != null
        )
          return this.challengeDurationByWeekDaysValues.series;
      } else if (this.dataType == "Effort") {
        if (this.splitByDay && this.challengeEffortByWeekDaysValues != null)
          return this.challengeEffortByWeekDaysValues.series;
        else if (
          this.splitByMonth &&
          this.challengeEffortByMonthsValues != null
        )
          return this.challengeEffortByMonthsValues.series;
      } else if (this.dataType == "Distance") {
        if (this.splitByDay && this.challengeDistanceByWeekDaysValues != null)
          return this.challengeDistanceByWeekDaysValues.series;
        else if (
          this.splitByMonth &&
          this.challengeDistanceByMonthsValues != null
        )
          return this.challengeDistanceByMonthsValues.series;
      }
      return null;
    },
  },
  watch: {
    title(newTitle, oldTitle) {
      console.log("title changed!");
      let options = this.options;
      options.title.text = newTitle;
      this.options = options;
    },
  },
  methods: {
    ...mapActions("charts", [
      "getPrimaryDataChartValues",
      "getChallengeDurationByWeekDays",
      "getChallengeDurationByMonths",
      "getChallengeEffortByWeekDays",
      "getChallengeEffortByMonths",
      "getChallengeDistanceByWeekDays",
      "getChallengeDistanceByMonths",
    ]),
  },
};
</script>
