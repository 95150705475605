<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <!-- <h3 color="green" @click="$router.push({ path: `/` })">
          Sport <BR />Challenges
        </h3> -->
        <v-btn style="height: 40px" text @click="$router.push({ path: `/` })">
          <span
            style="font-family: 'Comforter', serif; font-size: 18px"
            class="font-italic font-weight-bold"
            >SPORT<BR />Challenges</span
          >
          <!-- <span
            style="
              font-family: 'Comforter', serif;
              font-size: 40px;
              color: brown;
            "
            class="font-italic font-weight-bold"
            >DEBUG</span
          > -->
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn text @click="$router.push('/')">
        <span class="mr-2">Feed</span>
        <v-icon>mdi-rss</v-icon>
      </v-btn> -->
      <v-btn text @click="$router.push({ path: `/groups` })">
        <span class="mr-2">Groups</span>
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>
      <!-- <v-btn text @click="$router.push({ path: `/challenges` })">
        <span class="mr-2">Challenges</span>
        <v-icon>mdi-trophy-variant</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
};
</script>
