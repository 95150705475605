<template>
  <v-container>
    <v-card class="mx-auto" tile>
      <ul v-for="item in reports" :key="item.id">
        <v-list two-line subheader>
          <v-divider></v-divider>
          <v-subheader inset>{{ item.title }}</v-subheader>
          <v-list-item-content v-for="text in item.texts" :key="text.id">
            <v-list-item-title>{{ text.title }}</v-list-item-title>
            <v-list-item-subtitle
              v-for="content in text.content"
              :key="content.id"
            >
              &nbsp;&nbsp; * {{ content.data }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list>
      </ul>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "FeedsView",
  components: {},
  data() {
    return {
      reports: [
        {
          id: 12,
          title: "2022-09-14",
          texts: [
            {
              id: 121,
              title: "Refactorings",
              content: [
                {
                  id: 1211,
                  data: "Increased database performance by changing structure",
                },
                {
                  id: 1212,
                  data: "Hided users in challenges who have zero workouts",
                },
                {
                  id: 1213,
                  data: "Changed distance in charts from meters to kilometers",
                },
                {
                  id: 1214,
                  data: "Server side providers refactoring, added new provider - Strava. Not yet fully implemented.",
                },
              ],
            },
          ],
        },
        {
          id: 11,
          title: "2022-03-28",
          texts: [
            {
              id: 111,
              title: "Removals",
              content: [
                {
                  id: 1111,
                  data: "Ascent and descent data for workouts and challenges as data is incorrect",
                },
              ],
            },
          ],
        },
        {
          id: 10,
          title: "2022-03-24",
          texts: [
            {
              id: 101,
              title: "Adds",
              content: [
                {
                  id: 1011,
                  data: "Ascent and descent data for workouts and challenges",
                },
              ],
            },
          ],
        },
        {
          id: 9,
          title: "2022-03-13",
          texts: [
            {
              id: 91,
              title: "Adds",
              content: [
                {
                  id: 911,
                  data: "Bar chart",
                },
                {
                  id: 912,
                  data: "Bar charts of distance, effor and active time by week days and month.",
                },
              ],
            },
            {
              id: 92,
              title: "Improved",
              content: [
                {
                  id: 921,
                  data: "Challenge and workout layout for mobile devices",
                },
              ],
            },
          ],
        },
        {
          id: 8,
          title: "2022-02-19",
          texts: [
            {
              id: 81,
              title: "Adds",
              content: [
                {
                  id: 811,
                  data: "Heart rate tooltip with percentage and zones (challenges total data)",
                },
                {
                  id: 812,
                  data: "Pace (challenges total data)",
                },
              ],
            },
            {
              id: 82,
              title: "Improved",
              content: [
                {
                  id: 8121,
                  data: "Avrg heart rate and speed calculation formula by diving from duration / distance instead of count",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          title: "2022-02-17",
          texts: [
            {
              id: 71,
              title: "Adds",
              content: [
                {
                  id: 711,
                  data: "Heart rate percentage and watts per kilogram ",
                },
                {
                  id: 712,
                  data: "Workotus pagination count (before was hardcoded)",
                },
                {
                  id: 713,
                  data: "Workout update issues - workout updates within MapMyFitness now will reflect in SportChallenges",
                },
              ],
            },
            {
              id: 72,
              title: "Improvements",
              content: [
                {
                  id: 7121,
                  data: "Added different effort formula, at the moment for debug only.",
                },
              ],
            },
          ],
        },
        {
          id: 6,
          title: "2022-02-13",
          texts: [
            {
              id: 61,
              title: "Fixes",
              content: [
                {
                  id: 611,
                  data: "Layout improvements for mobile devices",
                },
              ],
            },
          ],
        },
        {
          id: 5,
          title: "2022-02-08",
          texts: [
            {
              id: 51,
              title: "Adds",
              content: [
                {
                  id: 511,
                  data: "SSL added, wohooo!",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          title: "2022-02-07",
          texts: [
            {
              id: 41,
              title: "Adds",
              content: [
                {
                  id: 411,
                  data: "Added workouts filter of challenge",
                },
                {
                  id: 412,
                  data: "Added challenge primary data chart (more to come)",
                },
              ],
            },
            {
              id: 42,
              title: "Fixes",
              content: [
                {
                  id: 4121,
                  data: "A few workouts have data of wilpower. WTF is that? Well, added.",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "2022-01-31",
          texts: [
            {
              id: 31,
              title: "Adds",
              content: [
                {
                  id: 311,
                  data: "Filtered workouts by challenge (UI not fully completed, disabled)",
                },
                {
                  id: 312,
                  data: "Challenge stats / charts (only partialy implemented, disabled)",
                },
              ],
            },
            {
              id: 32,
              title: "Fixes",
              content: [
                {
                  id: 3121,
                  data: "Double parsing issues, duplicate reference key issue, effort challenge wont load",
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: "2022-01-29",
          texts: [
            {
              id: 11,
              title: "Adds",
              content: [
                {
                  id: 111,
                  data: "Workout update functionality (when retrieved from provider)",
                },
                {
                  id: 112,
                  data: "Effort and Cadence data into workouts thumbnail",
                },
              ],
            },
            {
              id: 12,
              title: "Fixes",
              content: [
                {
                  id: 121,
                  data: "Performance improved for data load: groups, challenges and activities",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: "2022-01-28",
          texts: [
            {
              id: 21,
              title: "Adds",
              content: [
                { id: 211, data: "Group activities pagination" },
                {
                  id: 212,
                  data: "Calories calculation formula for Male and Female",
                },
                { id: 213, data: "User weight controller" },
                { id: 214, data: "Image types" },
              ],
            },
            {
              id: 22,
              title: "Fixes",
              content: [
                { id: 221, data: "Images are loaded by URL instead of Base64" },
                {
                  id: 222,
                  data: "Effort calculation formula (age and heart rate zone used instead of avrg heart rate)",
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>
