import Vue from "vue";
import VueRouter from "vue-router";
import FeedsView from "../views/FeedsView.vue";
import GroupsView from "../views/GroupsView.vue";
import ProviderAuthDetailView from "../views/auth/ProviderAuthDetailView.vue";
import ProviderAuthResultDetailView from "../views/auth/ProviderAuthResultDetailView.vue";
import GroupDetailView from "../views/GroupDetailView.vue";
import WorkoutDetailView from "../views/WorkoutDetailView.vue";
import ChallengeDetailView from "../views/ChallengeDetailView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/rubedos",
    redirect: "/group/1",
  },
  {
    path: "/",
    name: "Home",
    component: FeedsView,
  },
  {
    path: "/groups",
    name: "Groups",
    component: GroupsView,
  },
  {
    path: "/group/:groupId",
    name: "GroupDetail",
    component: GroupDetailView,
    props: (route) => ({ ...route.params }),
  },
  {
    path: "/workout/:workoutId",
    name: "WorkoutDetailView",
    component: WorkoutDetailView,
    props: (route) => ({ ...route.params }),
  },
  {
    path: "/challenge/:challengeId",
    name: "ChallengeDetailView",
    component: ChallengeDetailView,
    props: (route) => ({ ...route.params }),
  },
  {
    path: "/auth",
    name: "ProviderAuthResultDetailView",
    component: ProviderAuthResultDetailView,
    props: (route) => ({ code: route.query.code, scope: route.query.scope }),
  },
  {
    path: "/challenges",
    name: "Challenges",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChallengesView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

export default router;
